import { SpreadsheetComponent, SheetsDirective, SheetDirective, RowsDirective, RowDirective, CellsDirective, CellDirective, ColumnsDirective, ColumnDirective } from "@syncfusion/ej2-react-spreadsheet";
import { memo, useMemo } from "react";
import { useImperativeHandle } from "react";
import { forwardRef } from "react";
import { icplSelector } from "../redux/icpl_slice";
import { shallowEqual, useSelector } from "react-redux";
import ICPLFilterUtils from "../utils/filter";
import { useRef } from "react";
import Swal from "sweetalert2";
import moment from "moment";
import { useEffect } from "react";

const mapOrders = (orders, signatures) => {
    return orders.map(order => {
        const signature = {
            ProofOfDelivery: ''
        };
        const filterSignatures = signatures.filter(f => f.TicketNum === order.TicketNum);
        
        if (filterSignatures.length > 0) {
            const filterSignature = filterSignatures[0];
            const proofOfDeliveries= [];
            if (filterSignature.AcknowledgementType) proofOfDeliveries.push(filterSignature.AcknowledgementType);
            if (filterSignature.Signatures.Consultant) proofOfDeliveries.push('Consultant Signature');
            if (filterSignature.Signatures.ChemicalApplicator) proofOfDeliveries.push('Chemical Applicator Signature');
            signature.ProofOfDelivery = proofOfDeliveries.join(', ');
        }
        else {
            const proofStatus = (order.ProofStatus ?? '').toLowerCase();
            switch (proofStatus) {
                case 'ongoing':
                    signature.ProofOfDelivery = 'ONGOING';
                    break;
                case 'unsigned':
                    signature.ProofOfDelivery = 'UNSIGNED JOB';
                    break;
                case 'signed':
                    signature.ProofOfDelivery = 'SIGN ON GLASS';
                    break;
                default:
                    break;
            }
        }
        return {
            ...order,
            ...signature
        }
    });
}

const ICPLExcel = forwardRef((_, ref) => {
    const { orders, filter, downloadExcel, signatures, loading } = useSelector(icplSelector(['orders', 'filter', 'downloadExcel', 'signatures', 'loading']), shallowEqual);
    const mappedFilter = useMemo(() => new ICPLFilterUtils(filter).mapToExcel(), [filter]);
    const mappedOrders = useMemo(() => mapOrders(orders, signatures), [orders, signatures]);

    const spreadsheetRef = useRef();
    const init = useRef(true);

    const fetchingProgressTitle = "Fetching deliveries are still in progress";

    useImperativeHandle(ref, () => {
        return {
            download() {
                handleDownload();
            }
        }
    });

    useEffect(() => {
        if (!init.current) handleDownload();
        else init.current = false;
        // eslint-disable-next-line
    }, [downloadExcel]); 

    useEffect(() => {
        if (!loading && Swal.isVisible() && Swal.getTitle().textContent === fetchingProgressTitle) {
            setTimeout(() => {
                handleDownload();
            }, 1000);
        }
        // eslint-disable-next-line
    }, [loading]); 

    const handleDownload = () => {
        if (!loading) {
            Swal.fire({
                icon: 'info',
                title: 'Downloading Excel',
                text: 'Please wait...',
                backdrop: true,
                allowOutsideClick: () => !Swal.isLoading(),
                didOpen: async () => {
                    Swal.showLoading();
    
                    try {
                        await new Promise((resolve, reject) => {
                            spreadsheetRef.current.saveAsJson().then(response => {
                                let startDate, endDate = '';
                                if (filter.StartTime) startDate = `${moment(filter.StartTime).format('dd-MMM-yyyy')}`;
                                else startDate = `${moment().format('DD-MMM-yyyy')}`;
                                if (filter.EndTime) endDate = `_${moment(filter.EndTime).format('dd-MMM-yyyy')}`;
                                
                                const fileName = `Island_Concrete_Pte_Ltd_Customer_Delivery_${startDate}${endDate}`;
                                const extension = 'Xlsx';
                                var formData = new FormData();
                                formData.append('JSONData', JSON.stringify(response.jsonObject.Workbook));
                                formData.append('FileName', fileName);
                                formData.append('SaveType', extension);
                                formData.append('FileContentType', extension);
                                formData.append('VersionType', extension);
                                formData.append('PDFLayoutSettings', JSON.stringify({ "fitSheetOnOnePage": false }));
            
                                fetch(`https://findplus.w-locate.com/kimhock/scheduler/Report/ExtractReport`, {
                                    method: 'POST',
                                    body: formData
                                }).then(response => {
                                    response.blob().then(data => {
                                        const url = URL.createObjectURL(new Blob([data], { type: 'application/octet-stream' }));
                                        const anchor = document.createElement('a');
                                        anchor.href = url;
                                        anchor.setAttribute('download', `${fileName}.${extension}`);
                                        document.body.appendChild(anchor);
                                        anchor.click();
                                        anchor.remove();
                                        resolve();
                                    }, () => reject('Failed to generate file.'));
                                }, () => reject('Failed to Extract Report.'));
                            }, () => reject('Failed to saveAsJSON.'));
                        });
                        
                        Swal.close();
                    } catch (error) {
                        Swal.fire({
                            icon: 'error',
                            title: error
                        });
                    }
                }
            });
        }
        else {
            Swal.fire({
                icon: 'info',
                title: fetchingProgressTitle,
                text: 'Please wait...',
                allowOutsideClick: false,
                didOpen: () => Swal.showLoading(),
                // showLoaderOnConfirm: true,
                showCancelButton: true,
                showConfirmButton: false
            });
        }
    }

    const sanitizeValue = (value, defaultVal) => value ? value : defaultVal ? defaultVal : ' ';
    const convertDate = (value, isTime) => value ? moment(value).format(isTime ? 'HH:mm' : 'DD-MMM-yyyy HH:mm') : '--'

    return (
        <div style={{ display: 'none' }}>
            <SpreadsheetComponent 
                ref={spreadsheetRef}>
                <SheetsDirective>
                    <SheetDirective>
                        {/* <RangesDirective> */}
                        {/* <RangeDirective dataSource={dataSource} startCell={"A2"}></RangeDirective> */}
                        {/* </RangesDirective> */}
                        <RowsDirective>
                            <RowDirective>
                                <CellsDirective>
                                    <CellDirective value="Report"></CellDirective>
                                    <CellDirective colSpan={2} value="Customer Delivery"></CellDirective>
                                </CellsDirective>
                            </RowDirective>
                            {mappedFilter.map(val => {
                                return <RowDirective key={val.title}>
                                    <CellsDirective>
                                        <CellDirective value={val.title}></CellDirective>
                                        <CellDirective colSpan={2} format="@" value={val.isDateTime ? convertDate(val.value) : val.value}></CellDirective>
                                    </CellsDirective>
                                </RowDirective>
                            })}
                            <RowDirective></RowDirective>
                            <RowDirective>
                                <CellsDirective>
                                    <CellDirective value='LOAD DATE'></CellDirective>
                                    <CellDirective value='LOAD TIME'></CellDirective>
                                    <CellDirective value='TICKET NUM' ></CellDirective>
                                    <CellDirective value='VEHICLE'></CellDirective>
                                    <CellDirective value='PLANT NAME'></CellDirective>
                                    <CellDirective value='PROOF OF DELIVERY'></CellDirective>
                                    <CellDirective value='CUSTOMER NAME'></CellDirective>
                                    <CellDirective value='PROJECT NAME'></CellDirective>
                                    <CellDirective value='PROJECT LOCATION'></CellDirective>
                                    <CellDirective value='SITE DETAILS'></CellDirective>
                                    <CellDirective value='SITE PERSON'></CellDirective>
                                    <CellDirective value='SITE CONTACT NO'></CellDirective>
                                    <CellDirective value='DISTANCE (km)'></CellDirective>
                                    <CellDirective value='ARRIVED SITE'></CellDirective>
                                    <CellDirective value='UNLOAD START'></CellDirective>
                                    <CellDirective value='UNLOAD END'></CellDirective>
                                    <CellDirective value='LEFT SITE'></CellDirective>
                                    <CellDirective value='PRODUCT'></CellDirective>
                                    <CellDirective value='QUANTITY (mÂ³)'></CellDirective>
                                    <CellDirective value='PROGRESSIVE TOTAL (mÂ³)'></CellDirective>
                                    <CellDirective value='TOTAL ORDER (mÂ³)'></CellDirective>
                                    <CellDirective value='STRENGTH CLASS'></CellDirective>
                                    <CellDirective value='EXPOSURE CLASS'></CellDirective>
                                    <CellDirective value='CHLORIDE CLASS'></CellDirective>
                                    <CellDirective value='CONSISTENCE CLASS'></CellDirective>
                                    <CellDirective value='LIMITING VALUE 1'></CellDirective>
                                    <CellDirective value='LIMITING VALUE 2'></CellDirective>
                                    <CellDirective value='TYPE & STRENGTH CLASS 1'></CellDirective>
                                    <CellDirective value='TYPE & STRENGTH CLASS 2'></CellDirective>
                                    <CellDirective value='ADMIXTURE'></CellDirective>
                                    <CellDirective value='ADDITION TYPE'></CellDirective>
                                    <CellDirective value='AGGREGATE SIZE 1(mm)'></CellDirective>
                                    <CellDirective value='AGGREGATE SIZE 2(mm)'></CellDirective>
                                    <CellDirective value='STRUCTURAL ELEMENT'></CellDirective>
                                    <CellDirective value='CUBES MADE'></CellDirective>
                                    <CellDirective value='SLUMP (mm)'></CellDirective>
                                    <CellDirective value='TEMPERATURE (Â°C)'></CellDirective>
                                    <CellDirective value='FIELD ASSISTANT'></CellDirective>
                                    <CellDirective value='REMARKS'></CellDirective>
                                    <CellDirective value='PURCHASER REMARKS'></CellDirective>
                                    <CellDirective value='CHEMICAL APPLICATOR'></CellDirective>
                                    <CellDirective value='PURCHASER REPRESENTATIVE'></CellDirective>
                                        <CellDirective value={' '}></CellDirective>
                                </CellsDirective>
                            </RowDirective>
                            {mappedOrders.map(order => (
                                <RowDirective key={order.TicketNum}>
                                    <CellsDirective>
                                        <CellDirective format="@" value={convertDate(order.LoadStart)}></CellDirective>
                                        <CellDirective format="@" value={convertDate(order.LoadStart, true)}></CellDirective>
                                        <CellDirective value={sanitizeValue(order.TicketNum)} ></CellDirective>
                                        <CellDirective value={sanitizeValue(order.Vehicle)}></CellDirective>
                                        <CellDirective value={sanitizeValue(order.OriginName)}></CellDirective>
                                        <CellDirective value={sanitizeValue(order.ProofOfDelivery)}></CellDirective>
                                        <CellDirective value={sanitizeValue(order.CustomerName)}></CellDirective>
                                        <CellDirective value={sanitizeValue(order.ShipToName)}></CellDirective>
                                        <CellDirective value={sanitizeValue(order.ProjectLocation)}></CellDirective>
                                        <CellDirective value={sanitizeValue(order.ExtendedSiteDetails)}></CellDirective>
                                        <CellDirective value={sanitizeValue(order.ExtendedSiteContact)}></CellDirective>
                                        <CellDirective value={sanitizeValue(order.ExtendedSitePhone)}></CellDirective>
                                        <CellDirective value={sanitizeValue(order.Distance)}></CellDirective>
                                        <CellDirective format="@" value={convertDate(order.OnSite)}></CellDirective>
                                        <CellDirective format="@" value={convertDate(order.UnloadStart)}></CellDirective>
                                        <CellDirective format="@" value={convertDate(order.UnloadEnd)}></CellDirective>
                                        <CellDirective format="@" value={convertDate(order.LeftSite)}></CellDirective>
                                        <CellDirective value={sanitizeValue(order.ProductName)}></CellDirective>
                                        <CellDirective value={sanitizeValue(order.ProductQuantity)}></CellDirective>
                                        <CellDirective value={sanitizeValue(order.ExtendedCumulativeQty)}></CellDirective>
                                        <CellDirective value={sanitizeValue(order.TotalOrder)}></CellDirective>
                                        <CellDirective value={sanitizeValue(order.ExtendedSTRClass)}></CellDirective>
                                        <CellDirective value={sanitizeValue(order.ExtendedDCClass)}></CellDirective>
                                        <CellDirective value={sanitizeValue(order.ExtendedChlorideClass)}></CellDirective>
                                        <CellDirective value={sanitizeValue(order.ExtendedConsisClass)}></CellDirective>
                                        <CellDirective value={sanitizeValue(order.ExtendedLimitValue)}></CellDirective>
                                        <CellDirective value={sanitizeValue(order.LimitingValue2)}></CellDirective>
                                        <CellDirective value={sanitizeValue(order.ExtendedTypeStrength)}></CellDirective>
                                        <CellDirective value={sanitizeValue(order.TypeStrengthClass2)}></CellDirective>
                                        <CellDirective value={sanitizeValue(order.ExtendedAdmixType)}></CellDirective>
                                        <CellDirective value={sanitizeValue(order.ExtendedAdditionType)}></CellDirective>
                                        <CellDirective value={sanitizeValue(order.AggregateSize1)}></CellDirective>
                                        <CellDirective value={sanitizeValue(order.AggregateSize2)}></CellDirective>
                                        <CellDirective value={sanitizeValue(order.StructuralElement)}></CellDirective>
                                        <CellDirective value={sanitizeValue(order.CubesMade)}></CellDirective>
                                        <CellDirective value={sanitizeValue(order.Slump)}></CellDirective>
                                        <CellDirective value={sanitizeValue(order.Temperature)}></CellDirective>
                                        <CellDirective value={sanitizeValue(order.FieldAssistant)}></CellDirective>
                                        <CellDirective value={sanitizeValue(order.Remarks)}></CellDirective>
                                        <CellDirective value={sanitizeValue(order.DeliveryRemarks)}></CellDirective>
                                        <CellDirective value={sanitizeValue(order.ChemicalAdmixApplicator1)}></CellDirective>
                                        <CellDirective value={sanitizeValue(order.Recipient)}></CellDirective>
                                        <CellDirective value={' '}></CellDirective>
                                    </CellsDirective>
                                </RowDirective>
                            ))}
                        </RowsDirective>
                        <ColumnsDirective>
                            <ColumnDirective width={70}></ColumnDirective>
                            <ColumnDirective width={70}></ColumnDirective>
                            <ColumnDirective width={70}></ColumnDirective>
                            <ColumnDirective width={70}></ColumnDirective>
                            <ColumnDirective width={70}></ColumnDirective>
                            <ColumnDirective width={70}></ColumnDirective>
                            <ColumnDirective width={70}></ColumnDirective>
                            <ColumnDirective width={70}></ColumnDirective>
                            <ColumnDirective width={70}></ColumnDirective>
                            <ColumnDirective width={70}></ColumnDirective>
                            <ColumnDirective width={70}></ColumnDirective>
                            <ColumnDirective width={70}></ColumnDirective>
                            <ColumnDirective width={70}></ColumnDirective>
                            <ColumnDirective width={70}></ColumnDirective>
                            <ColumnDirective width={70}></ColumnDirective>
                            <ColumnDirective width={70}></ColumnDirective>
                            <ColumnDirective width={70}></ColumnDirective>
                            <ColumnDirective width={70}></ColumnDirective>
                            <ColumnDirective width={70}></ColumnDirective>
                            <ColumnDirective width={70}></ColumnDirective>
                            <ColumnDirective width={70}></ColumnDirective>
                            <ColumnDirective width={70}></ColumnDirective>
                            <ColumnDirective width={70}></ColumnDirective>
                            <ColumnDirective width={70}></ColumnDirective>
                            <ColumnDirective width={70}></ColumnDirective>
                            <ColumnDirective width={70}></ColumnDirective>
                            <ColumnDirective width={70}></ColumnDirective>
                            <ColumnDirective width={70}></ColumnDirective>
                            <ColumnDirective width={70}></ColumnDirective>
                            <ColumnDirective width={70}></ColumnDirective>
                            <ColumnDirective width={70}></ColumnDirective>
                            <ColumnDirective width={70}></ColumnDirective>
                            <ColumnDirective width={70}></ColumnDirective>
                            <ColumnDirective width={70}></ColumnDirective>
                            <ColumnDirective width={70}></ColumnDirective>
                            <ColumnDirective width={70}></ColumnDirective>
                            <ColumnDirective width={70}></ColumnDirective>
                            <ColumnDirective width={70}></ColumnDirective>
                            <ColumnDirective width={70}></ColumnDirective>
                            <ColumnDirective width={70}></ColumnDirective>
                            <ColumnDirective width={70}></ColumnDirective>
                            <ColumnDirective width={70}></ColumnDirective>
                        </ColumnsDirective>
                    </SheetDirective>
                </SheetsDirective>
            </SpreadsheetComponent>
        </div>
    );
});

export default memo(ICPLExcel);