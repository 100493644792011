import { forwardRef, memo, useEffect, useImperativeHandle, useRef, useState } from "react";
import ICPLBEService from "../../../services/icpl_backend";
import Swal from "sweetalert2";
import { shallowEqual, useSelector } from "react-redux";
import { icplSelector } from "../redux/icpl_slice";
import { DialogComponent } from "@syncfusion/ej2-react-popups";
import useIsMobile from "../../../utils/use_is_mobile";
import { Stack } from "@mui/material";
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import moment from "moment";

const DownloadPDFPromt = () => {
    const { downloadPDF, selectedOrders } = useSelector(icplSelector(['downloadPDF', 'selectedOrders']), shallowEqual);
    const { isMobile } = useIsMobile();

    const [dialogConfig, setDialogConfig] = useState({
        visible: false,
        header: '',
    });

    const contentRef = useRef();

    useEffect(() => {
        if (selectedOrders.length > 0) {
            if (selectedOrders.length > 1) {
                setDialogConfig({
                    visible: true,
                    header: `${selectedOrders.length} eDos selected`,
                });
                contentRef.current.setSelectedOrders(selectedOrders);
            }
            else DialogContent.downloadIndividualPDF(selectedOrders);
        }
        // eslint-disable-next-line
    }, [downloadPDF]);

    const handleDialogClose = () => {
        setDialogConfig({
            visible: false,
            header: ''
        });
    }

    return (
        <DialogComponent
            id="icpl_downlod_pdf_dialog"
            close={handleDialogClose}
            closeOnEscape={false}
            width={isMobile ? '95%' : '25%'}
            height={'20%'}
            isModal={true}
            showCloseIcon={true}
            header={dialogConfig.header}
            visible={dialogConfig.visible}
            style={{ borderRadius: '10px' }}
            content={() => <DialogContent ref={contentRef} onClose={handleDialogClose} />}
        >

        </DialogComponent>
    );
}

const DialogContent = forwardRef(({ onClose }, ref) => {
    const [selectedOrders, setSelectedOrders] = useState([]);

    useImperativeHandle(ref, () => {
        return {
            setSelectedOrders(data) {
                setSelectedOrders(data);
            }
        }
    })

    const downloadMergedPDF = () => {
        const pdfService = ICPLBEService.getPDFService();
        Swal.fire({
            icon: 'info',
            title: 'Downloading merged eDos',
            allowOutsideClick: !Swal.isLoading(),
            didOpen: async () => {
                Swal.showLoading();
                setTimeout(async () => {
                    try {
                        const ticketNums = selectedOrders.map(val => val.TicketNum);
                        const downloadMergeReq = await pdfService.downloadMergedPDF(ticketNums);
                        if (downloadMergeReq.status === 200) {
                            var myblob = new Blob([downloadMergeReq.data], {
                                type: 'application/pdf'
                            });
                            var url = window.URL.createObjectURL(myblob);
                            var link = document.createElement('a');
                            link.href = url;
                            link.download = `CustomerDeliveryExport_${moment().format('YYYYMMDDHHmm')}.pdf`;
                            link.dispatchEvent(new MouseEvent('click'));
                        }

                        Swal.close();
                    } catch (error) {
                        console.log(error)
                        Swal.fire({
                            icon: 'error',
                            title: 'Failed to merge PDF!'
                        });
                    }
                }, 1000);
            }
        });
    }

    const handleBtnOnClick = action => {
        onClose();

        if (action === "Merged") downloadMergedPDF();
        else DialogContent.downloadIndividualPDF(selectedOrders);
    }

    return (
        <>
            <div style={{ padding: '18px 0' }}>
                <div className="center-content">
                    <span style={{
                        fontSize: '18px',
                        textAlign: 'center',
                        fontWeight: '500'
                    }}>How would you like to download these eDOs?</span>
                </div>
            </div>
            <Stack spacing={1} direction={'row'} justifyContent={'center'}>
                <ButtonComponent
                    type="button"
                    isPrimary={true}
                    onClick={() => handleBtnOnClick('Merged')}
                    style={{
                        width: '130px'
                    }}>
                    Merged
                </ButtonComponent>
                <ButtonComponent
                    type="button"
                    isPrimary={true}
                    onClick={() => handleBtnOnClick('Individual')}
                    style={{
                        width: '130px'
                    }}>
                    Individual
                </ButtonComponent>
            </Stack>
        </>
    )
});

DialogContent.downloadIndividualPDF = (selectedOrders) => {
    const pdfService = ICPLBEService.getPDFService();
    Swal.fire({
        icon: 'info',
        title: 'Downloading eDos',
        allowOutsideClick: !Swal.isLoading(),
        didOpen: async () => {
            Swal.showLoading();
            setTimeout(async () => {
                try {
                    const downloadReq = await pdfService.downloadPDF(selectedOrders[0].TicketNum);
                    if (downloadReq.status === 200) {
                        var myblob = new Blob([downloadReq.data], {
                            type: 'application/pdf'
                        });
                        var url = window.URL.createObjectURL(myblob);
                        var link = document.createElement('a');
                        link.href = url;
                        link.download = `${selectedOrders[0].TicketNum}.pdf`;
                        link.dispatchEvent(new MouseEvent('click'));
                    }
                    
                    Swal.close();
                } catch (error) {
                    Swal.fire({
                        icon: 'error',
                        title: 'PDF not found!'
                    });
                }
            }, 1000);
        }
    });
}

export default memo(DownloadPDFPromt);