import { useEffect } from "react";
import ICPLFilter from "./components/Filter";
import { useDispatch } from "react-redux";
import { icplReset } from "./redux/icpl_slice";
import ICPLTable from "./components/Table";
import Spacing from "../../components/Spacing";
import ICPLExcel from './components/Excel';

export default function ICPLPage() {
    const dispatch = useDispatch();

    useEffect(() => {
        return () => dispatch(icplReset());
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <ICPLFilter />
            <Spacing />
            <ICPLTable />
            <ICPLExcel />
        </>
    );
}