import { createSlice } from '@reduxjs/toolkit';
import StateSelector from '../../../app/selector';
import DeliveryOrders from '../utils/delivery_records';
import moment from 'moment';

export const icplUseFormDefaultVal = {
  StartDateTime: moment().set('hours', 0).set('minutes', 0).toISOString(),
  EndDateTime: moment().set('hours', 0).set('minutes', 0).add(1, 'days').toISOString(),
  DeliveryStatus: 'Any',
  Vehicle: null,
  CustomerName: null,
  // CustomerCode: '',
  Origin: null,
  Destination: null,
};

const initialState = {
  filter: icplUseFormDefaultVal,
  textSearch: '',
  orders: [],
  rawOrders: [],
  signatures: [],
  customers: [],
  vehicles: [],
  origins: [],
  destinations: [],
  selectedOrders: [],
  downloadExcel: true,
  downloadPDF: true,
  triggerSearch: 1,
  loading: false,
}

export const icplSlice = createSlice({
  name: 'icpl',
  initialState: initialState,
  reducers: {
    setICPLFilter: (state, action) => {
      state.filter = {
        ...state.filter,
        ...action.payload
      };
    },
    icplReset: () => {
      return initialState;
    },
    setOrders: (state, action) => {
      state.selectedOrders = [];
      state.rawOrders = action.payload;
      state.orders = DeliveryOrders.mapOrders(action.payload);
    },
    setSignatures: (state, action) => {
      state.signatures = action.payload.map(val => val.data);
    },
    setCustomers: (state, action) => {
      state.customers = action.payload;
    },
    setVehicles: (state, action) => {
      state.vehicles = action.payload;
    },
    setOrigins: (state, action) => {
      state.origins = action.payload;
    },
    setDestinations: (state, action) => {
      state.destinations = action.payload;
    },
    setSelectedOrders: (state, action) => {
      state.selectedOrders = action.payload;
    },
    downloadICPLExcel: (state) => {
      state.downloadExcel = !state.downloadExcel;
    },
    downloadICPLPDF: (state) => {
      state.downloadPDF = !state.downloadPDF;
    },
    setTextSearch: (state, action) => {
      state.textSearch = action.payload ? action.payload : '';
    },
    setTriggerSearch: (state) => {
      state.triggerSearch = ++state.triggerSearch;
    },
    setLoading: (state) => {
      state.loading = !state.loading;
    }
  }
})

export const {
  setICPLFilter,
  icplReset,
  setOrders,
  setSignatures,
  setCustomers,
  setVehicles,
  setOrigins,
  setDestinations,
  setSelectedOrders,
  downloadICPLExcel,
  downloadICPLPDF,
  setTextSearch,
  setTriggerSearch,
  setLoading } = icplSlice.actions;

export const icplSelector = StateSelector('icpl');

export const icplStore = (store, items) => StateSelector('icpl')(items)(store.getState());

export default icplSlice.reducer