import { useEffect } from 'react';
import { Viewer } from '@react-pdf-viewer/core';
import Swal from 'sweetalert2';
import { useState } from 'react';

export default function PdfViewer(props) {
    const [isMissingPdf, setIsMissingPdf] = useState(false);
    const data = props.data;

    useEffect(() => {
        if (!props.visible) {
            const pdfLoader = document.querySelector('.pdf-viewer-overlay');
            pdfLoader.classList.remove('hide');
        }
        else {
            setIsMissingPdf(false);
        }
        // eslint-disable-next-line
    }, [props.visible]);

    const handleDocumentLoad = () => {
        const pdfLoader = document.querySelector('.pdf-viewer-overlay');
        pdfLoader.classList.add('hide');
    }

    const handleRenderError = (error) => {
        if (props.visible) {
            let message = '';
            let showSwal = true;
            switch (error.name) {
                case 'InvalidPDFException':
                    message = 'The document is invalid or corrupted';
                    break;
                case 'MissingPDFException':
                    message = 'The document is missing';
                    if (props.isICPLPage) {
                        showSwal = false;
                        setTimeout(() => {
                            setIsMissingPdf(true);
                        }, 500);
                    }
                    break;
                case 'UnexpectedResponseException':
                    message = 'Unexpected server response';
                    break;
                default:
                    message = 'Cannot load the document';
                    break;
            }

            if (showSwal) {
                Swal.fire({
                    icon: 'error',
                    title: message,
                    text: 'Try again'
                });
                props.close();
            }
        }
    }

    const getPodSearchParams = () => {
        if (props.isUnsignedDelivery) {
            return `id=&tx=${btoa(data.TicketNum)}&company=${btoa(data.CompanyID)}`;
        }

        return `id=${data.deliveryKey}`;
    }

    return (
        <div className='pdf-viewer-container'>
            <div className='pdf-viewer-overlay'>
                <div className="loader-container">
                    <div className="lds-dual-ring"></div>
                </div>
            </div>
            <div style={{ border: '1px solid rgba(0, 0, 0, 0.1)', width: '100%', height: '100%' }}>
                {props.visible && !props.isUnsignedDelivery && !isMissingPdf && <Viewer
                    fileUrl={data.fileUrl}
                    defaultScale={1.5}
                    onDocumentLoad={handleDocumentLoad}
                    renderError={handleRenderError} />}
                {props.visible && props.isICPLPage && (props.isUnsignedDelivery || isMissingPdf) && <>
                    <iframe 
                        title='PDF' 
                        sandbox="allow-scripts"
                        onLoad={handleDocumentLoad}
                        src={`https://findplus.w-locate.com/DeliveryFormICPL/ProofOfDeliveryICPL.aspx?${getPodSearchParams()}`}
                        style={{
                            height: '100%',
                            width: '100%'
                        }}></iframe>
                </>}
            </div>
        </div>
    );
}