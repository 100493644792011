import { axiosICPLBEInstance } from "../../utils/axios_config";
import EDeliveryOrderService from "./controllers/e_delivery_order";
import EPDFService from "./controllers/pdf";

function ICPLBEService() {}

ICPLBEService.getEDeliveryOrderService = () => new EDeliveryOrderService();
ICPLBEService.getPDFService = () => new EPDFService();
ICPLBEService.baseUrl = axiosICPLBEInstance.defaults.baseURL;

export default ICPLBEService;
