import { forwardRef, useState } from "react";
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import PdfViewer from "../../../components/PdfViewer";
import { useImperativeHandle } from "react";
import ICPLBEService from "../../../services/icpl_backend";
import useIsMobile from "../../../utils/use_is_mobile";
import DownloadPDFPromt from "./DownloadPDFPromt";
import Swal from "sweetalert2";

const PDFPreview = forwardRef((_, ref) => {
    const { isMobile } = useIsMobile();
    const [dialogConfig, setDialogConfig] = useState({
        visible: false,
        header: '',
        isUnsignedDelivery: false,
        data: {}
    })

    useImperativeHandle(ref, () => {
        return {
            show(data, isUnsignedDelivery = false) {
                if (!isUnsignedDelivery) {
                    const pdfService = ICPLBEService.getPDFService();
                    const fileUrl = pdfService.getStreamedPDF(data.TicketNum);
                    setDialogConfig({
                        header: `Proof of Delivery Receipt - ${data.TicketNum}`,
                        visible: true,
                        isUnsignedDelivery: isUnsignedDelivery,
                        data: {
                            fileUrl: fileUrl,
                            deliveryKey: data.DeliveryKey,
                        }
                    });
                }
                else {
                    if (data.CompanyID) {
                        setDialogConfig({
                            header: `Proof of Delivery Receipt - ${data.TicketNum}`,
                            visible: true,
                            isUnsignedDelivery: isUnsignedDelivery,
                            data: data
                        });
                    }
                    else {
                        Swal.fire({
                            icon: 'error',
                            title: 'No Company ID Found!',
                        });
                    }
                }
            }
        }
    });

    const handleDialogClose = () => {
        setDialogConfig({
            visible: false,
            pdfUrl: '',
            deliveryKey: '',
            header: ''
        });
    }

    return (
        <>
            <DownloadPDFPromt />
            <DialogComponent
                id="icpl-table-dialog"
                close={handleDialogClose}
                closeOnEscape={false}
                width={isMobile ? '95%' : '980px'}
                isModal={true}
                showCloseIcon={true}
                header={dialogConfig.header}
                // target='#dialog-target' 
                visible={dialogConfig.visible}
            // close={dialogClose} 
            // overlayClick={onOverlayClick}
            >
                <PdfViewer 
                    visible={dialogConfig.visible} 
                    isICPLPage={true} 
                    isUnsignedDelivery={dialogConfig.isUnsignedDelivery}
                    data={dialogConfig.data}
                    close={handleDialogClose} />
            </DialogComponent>
        </>
    );
});

export default PDFPreview;