import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Navigate } from "react-router-dom";
import { RouterRoutes } from "../../App";
import Loader from "../Loader";
import AthenaSettings from "../../utils/athena_settings";
import { setAccount } from "../../container/login/redux/login_slice";
import moment from "moment";

export default function AuthGuard(props) {
    const [auth, setAuth] = useState({
        isAuthenticated: false,
        showLoader: true,
        navigateTo: RouterRoutes.login
    });
    const dispatch = useDispatch();

    useEffect(() => {
        setTimeout(() => {
            let authInfo = { ...auth };
            if (!auth.isAuthenticated) {
                const settings = new AthenaSettings();
                //authenticate if rememberMe is false but the date diff is <= 0
                const rememberMe = settings.value.rememberMe.value || moment().diff(moment(settings.value.rememberMe.date), "days") === 0;
                const account = settings.value.account;
                if (account && rememberMe) {
                    settings.value.rememberMe.date = moment().add(1, 'days');
                    settings.saveChanges();
                    
                    dispatch(setAccount(account));
                    authInfo.isAuthenticated = true;
                    authInfo.showLoader = false;
                }
                else authInfo.showLoader = false;
            }
            else {
                authInfo.isAuthenticated = true;
                authInfo.showLoader = false;
            }

            setAuth(authInfo)
        }, 500);
        // eslint-disable-next-line
    }, []);

    if (auth.showLoader) return <Loader />
    else if (auth.isAuthenticated) return props.element;
    else return <Navigate to={auth.navigateTo} replace />
}