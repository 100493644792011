import { createSpinner, hideSpinner, showSpinner } from "@syncfusion/ej2-react-popups";
import { useState } from "react";
import { useEffect } from "react";

export const SignatureType = {
    signOnGlass: 'signOnGlass',
    unsigned: 'unsigned',
    ongoing: 'ongoing'
}

export default function SignatureTemplate({ data, signatureLoader, onClick }) {
    const proofStatus = (data.ProofStatus ?? '').toLowerCase();
    const [proof, setProof] = useState(null);
    const [isFetching, setIsFetching] = useState(false);

    useEffect(() => {
        if (signatureLoader && proofStatus === 'signed' && data.DeliveryKey) {
            setIsFetching(true);

            const id = `signatureTemplateSpinner${data.TicketNum}`;
            createSpinner({
                target: document.getElementById(id),
            });
            showSpinner(document.getElementById(id));

            signatureLoader.addQueue(data.DeliveryKey, result => {
                if (result.isSuccess) {
                    setProof(result.data);
                }

                hideSpinner(document.getElementById(id));
                setIsFetching(false);
            });
        }
        // eslint-disable-next-line
    }, []);

    return (
        <div className='image'>
            {proofStatus === 'ongoing' && <span className="sign-on-glass-label" onClick={() => onClick({
                type: SignatureType.ongoing,
                data: data
            })} style={{ color: 'green' }}>ONGOING</span>}
            {proofStatus === 'unsigned' && <span className="sign-on-glass-label" onClick={() => onClick({
                type: SignatureType.unsigned,
                data: data
            })} style={{ color: 'red' }}>UNSIGNED JOB</span>}
            {!isFetching && proof && proofStatus === 'signed' && <>
                <span className="sign-on-glass-label" onClick={() => onClick({
                    type: SignatureType.signOnGlass,
                    data: data
                })}>{proof.AcknowledgementType}</span>
                {proof.Signatures.Receiver && <>
                    <br />
                    <img src={`data:image/jpeg;base64,${proof.Signatures.Receiver}`} alt="Receiver Signature" height={80} width={100} />
                </>}
                {proof.Signatures.Consultant && <>
                    <br />
                    <span style={{ textWrap: 'wrap' }}>Consultant Signature</span>
                    <br />
                    <img src={`data:image/jpeg;base64,${proof.Signatures.Consultant}`} alt="Consultant Signature" height={80} width={100} />
                </>}
                {proof.Signatures.ChemicalApplicator && <>
                    <br />
                    <span style={{ textWrap: 'wrap' }}>Chemical Applicator Signature</span>
                    <br />
                    <img src={`data:image/jpeg;base64,${proof.Signatures.ChemicalApplicator}`} alt="Chemical Applicator Signature" height={80} width={100} />
                </>}
            </>}
            <div id={`signatureTemplateSpinner${data.TicketNum}`} style={isFetching ? { position: 'relative', height: '40px' } : {}}></div>
        </div>
    );
}