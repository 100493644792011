import IntegrationAccountService from "./controllers/account";
import IntegrationCustomerService from "./controllers/customer";
import IntegrationProofService from "./controllers/proof";
import IntegrationVehicleService from "./controllers/vehicles";
import IntegrationZoneService from "./controllers/zone";

function FindPlusIntegration() {}

FindPlusIntegration.getAccountService = () => new IntegrationAccountService();
FindPlusIntegration.getVehicleService = () => new IntegrationVehicleService();
FindPlusIntegration.getZoneService = () => new IntegrationZoneService();
FindPlusIntegration.getCustomerService = () => new IntegrationCustomerService();
FindPlusIntegration.getProofService = () => new IntegrationProofService();

export default FindPlusIntegration;
