import { memo, useEffect, useMemo, useRef } from "react";
import { createRoot } from 'react-dom/client';
import { GridComponent, ColumnDirective, ColumnsDirective, Toolbar, ExcelExport, Inject, Page, Resize, Sort } from "@syncfusion/ej2-react-grids";
import useIsMobile from "../../../utils/use_is_mobile";
// import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import { useTranslation } from "react-i18next";
import TableToolbar from "./TableToolbar";
import { shallowEqual, useDispatch, useSelector, useStore } from "react-redux";
import { icplSelector, icplStore, setOrders, setSelectedOrders, setSignatures } from "../redux/icpl_slice";
import moment from "moment/moment";
import PDFPreview from "./PDFPreview";
import SignatureLoader from "../utils/signature_loader";
import SignatureTemplate, { SignatureType } from "./SignatureTemplate";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";
import OngoingJobDialog from "./OngoingJobDialog";
import PaginationCtrl from "./PaginationCtrl";

const mapOrders = (orders) => {
    if (orders.length > 0) {
        return orders.map(order => {
            return {
                ...order,
                LoadStartDate: moment(order.LoadStart).toDate(),
                LoadStartTime: moment(order.LoadStart).toDate(),
                OnSiteDate: order?.OnSite ? moment(order?.OnSite).toDate() : null,
                UnloadStartDate: order?.UnloadStart ? moment(order?.UnloadStart).toDate() : null,
                UnloadEndDate: order?.UnloadEnd ? moment(order?.UnloadEnd).toDate() : null,
                LeftSiteDate: order?.LeftSite ? moment(order?.LeftSite).toDate() : null,
            }
        });
    }

    return [];
}

const ICPLTable = () => {
    const store = useStore();
    const { orders, loading } = useSelector(icplSelector(['orders', 'loading']), shallowEqual);
    const mappedOrders = useMemo(() => mapOrders(orders), [orders]);
    const dispatch = useDispatch();
    const { isMobile } = useIsMobile();
    const { t } = useTranslation();

    const signatureLoader = useRef(new SignatureLoader((signatures) => {
        dispatch(setSignatures(signatures));
    }));
    const pdfPreviewRef = useRef();
    const ongoingJobDialogRef = useRef();
    const gridRef = useRef();

    // useEffect(() => {
    //     fetchEdo();
    //     // eslint-disable-next-line
    // }, []);

    useEffect(() => {
        if (gridRef.current) gridRef.current.refreshColumns();
        // eslint-disable-next-line
    }, [t]);

    useEffect(() => {
        if (loading) {
            signatureLoader.current.cancelQueue();
            if (gridRef.current) gridRef.current.pageSettings.currentPage = 1;
        }
    }, [loading]);

    // const fetchEdo = async () => {
    //     Swal.fire({
    //         icon: 'info',
    //         title: 'Please wait',
    //         backdrop: true,
    //         reverseButtons: true,
    //         didOpen: async () => {
    //             Swal.showLoading();
    //             try {
    //                 const customerService = FindPlusIntegration.getCustomerService();
    //                 const vehicleService = FindPlusIntegration.getVehicleService();
    //                 const zoneService = FindPlusIntegration.getZoneService();

    //                 const [customers, origins, projectSites] = await Promise.all([
    //                     customerService.getCustomers(),
    //                     zoneService.getOrigins(),
    //                     zoneService.getProjectSites()
    //                 ]);


    //                 const vehiclePayload = {
    //                     PageNumber: 1,
    //                     PageSize: 100
    //                 }
    //                 let canFetch = false;
    //                 let vehicles = [];

    //                 do {
    //                     const vehiclesReq = await vehicleService.getVehicles(vehiclePayload);
    //                     const vehicleData = vehiclesReq.data;
    //                     canFetch = vehicleData.length > 0 && vehicleData.length === vehiclePayload.PageSize;
    //                     vehicles = vehicles.concat(vehicleData);
    //                     if (canFetch) {
    //                         vehiclePayload.PageNumber += 1;
    //                         vehiclePayload.ItemID = vehicleData[vehicleData.length - 1].VehicleID;
    //                     }
    //                 } while (canFetch);


    //                 dispatch(setCustomers(customers.data));
    //                 // dispatch(setVehicles(vehicles));
    //                 dispatch(setOrigins(origins.data));
    //                 dispatch(setDestinations(projectSites.data));
    //                 Swal.close();
    //             } catch (e) {
    //                 console.log(e);
    //                 Swal.fire({
    //                     icon: 'error',
    //                     title: 'Failed to fetch delivery order'
    //                 });
    //             }
    //         },
    //         allowOutsideClick: () => !Swal.isLoading()
    //     });
    // }

    const rowDataBound = (args) => {
        const status = args.data.ProofStatus;
        if (status && status.toLowerCase() === 'ongoing') {
            const checkboxElem = args.row.firstChild;
            if (checkboxElem.firstElementChild) checkboxElem.removeChild(checkboxElem.firstElementChild);
            const root = createRoot(checkboxElem);
            root.render(<>
                <img src="assets/ongoing.png" alt="Ongoing" />
            </>)
        }
        else if (status && status.toLowerCase() === 'unsigned') {
            const checkboxElem = args.row.firstChild;
            if (checkboxElem.firstElementChild) checkboxElem.removeChild(checkboxElem.firstElementChild);
            const root = createRoot(checkboxElem);
            root.render(<>
                <img src="assets/alert.png" alt="Alert" />
            </>)
        }
        else if (status && status.toLowerCase() === 'signed' && !args.data.FileExportTimestamp) {
            const checkboxElem = args.row.firstChild;
            if (checkboxElem.firstElementChild) checkboxElem.removeChild(checkboxElem.firstElementChild);
            const root = createRoot(checkboxElem);
            root.render(<>
                <TooltipComponent content="No PDF file exported" position="RightCenter">
                    <span className="e-icons e-medium e-warning" style={{ color: 'orange' }}></span>
                </TooltipComponent>
            </>)
        }

        const { selectedOrders } = icplStore(store, ['selectedOrders']);
        const filteredOrders = selectedOrders.filter(f => f.TicketNum === args.data.TicketNum);

        if (filteredOrders.length > 0) {
            const checkbox = args.row.children[0].children[0].children[0];
            if (checkbox.id) {
                setTimeout(() => {
                    const checkboxEl = document.getElementById(checkbox.id);
                    if (checkboxEl) checkboxEl.click()
                }, 500);
            }

            // const checkboxIcon = args.row.children[0].children[0].children[1];
            // if (checkboxIcon && checkboxIcon.classList.includes('e-uncheck')) console.log(true);
            // checkboxIcon.classList.add('e-check');
        }

        if (args.row.ariaRowIndex % 2 === 0) {
            args.row.classList.add('bg-grey');
        }

        else {

        }

        if (gridRef.current) gridRef.current.height = gridRef.current.getCurrentViewRecords().length > 15 ? 700 : 'auto';
    }

    // const tableActionTemplate = (_) => {
    //     return (
    //         <ButtonComponent cssClass='e-flat e-medium e-round' tooltip='Download' className="e-icons e-download" onClick={() => { }} isPrimary></ButtonComponent>
    //     );
    // }

    const handleSignatureOnClick = async args => {
        switch (args.type) {
            case SignatureType.signOnGlass:
                if (pdfPreviewRef.current) pdfPreviewRef.current.show(args.data);
                break;
            case SignatureType.unsigned:
                if (pdfPreviewRef.current) pdfPreviewRef.current.show(args.data, true);
                break;
            case SignatureType.ongoing:
                if (ongoingJobDialogRef.current) ongoingJobDialogRef.current.show(args.data);
                break;
            default:
                break;
        }
    }

    const signatureTemplate = args => (
        <SignatureTemplate
            data={args}
            onClick={handleSignatureOnClick}
            signatureLoader={signatureLoader.current} />
    );

    // const handleSearchOnChange = value => {
    //     if (gridRef.current) gridRef.current.search(value);
    // }

    const handleCheckBoxChange = () => {
        if (gridRef.current) dispatch(setSelectedOrders(gridRef.current.getSelectedRecords()));
    }

    const paginationTemplate = (pagerData) => {
        return <PaginationCtrl gridRef={gridRef.current} pagerData={pagerData} />
    }

    return (
        <>
            <TableToolbar />
            <GridComponent
                ref={gridRef}
                dataSource={mappedOrders}
                rowDataBound={rowDataBound}
                enableAdaptiveUI={isMobile}
                allowSorting={true}
                allowExcelExport={false}
                allowPaging={true}
                allowResizing={true}
                gridLines="Both"
                checkBoxChange={handleCheckBoxChange}
                // , pageSize: '50', pageSizes: ['10', '20', '50', '100', '200']
                pageSettings={{ template: paginationTemplate, pageSize: 50 }}
                allowEditing={false}
                allowTextWrap={false}
                selectionSettings={{
                    checkboxOnly: true,
                }}
            >
                <ColumnsDirective>
                    <ColumnDirective type='checkbox' className='bg-blue' allowResizing={false} width="65" textAlign="left" />
                    <ColumnDirective field='LoadStartDate' format={{ type: 'date', format: 'dd-MMM-yyyy' }} minWidth={50} width={140} headerText={t('icpl.columnLoadDate')} />
                    <ColumnDirective field='LoadStartTime' format={{ type: 'dateTime', format: 'HH:mm' }} minWidth={50} width={140} headerText={t('icpl.columnLoadTime')} />
                    <ColumnDirective field='TicketNum' minWidth={50} width={140} headerText={t('icpl.columnTicketNo')} />
                    <ColumnDirective field='Vehicle' minWidth={50} width={140} headerText={t('icpl.columnVehicle')} />
                    <ColumnDirective field='OriginName' minWidth={50} width={170} headerText={t('icpl.columnPlantName')} />
                    <ColumnDirective field="ProofStatus" template={signatureTemplate} minWidth={180} width={190} headerText={t('icpl.columnProofDelivery')} />
                    <ColumnDirective field='CustomerName' minWidth={50} width={190} headerText={t('icpl.columnCustomerName')} />
                    <ColumnDirective field='ShipToName' minWidth={50} width={190} headerText={t('icpl.columnProjectName')} />
                    <ColumnDirective field='ProjectLocation' minWidth={50} width={190} headerText={t('icpl.columnProjectLocation')} />
                    <ColumnDirective field='ExtendedSiteDetails' minWidth={50} width={190} headerText={t('icpl.columnSiteDetails')} />
                    <ColumnDirective field='ExtendedSiteContact' minWidth={50} width={170} headerText={t('icpl.columnSitePerson')} />
                    <ColumnDirective field='ExtendedSitePhone' minWidth={50} width={170} headerText={t('icpl.columnSiteContact')} />
                    <ColumnDirective field='Distance' minWidth={50} width={170} headerText={t('icpl.columnDistance')} />
                    <ColumnDirective field='OnSiteDate' format={{ type: 'dateTime', format: 'dd-MMM-yyyy HH:mm' }} minWidth={50} width={150} headerText={t('icpl.columnArrivedSite')} />
                    <ColumnDirective field='UnloadStartDate' format={{ type: 'dateTime', format: 'dd-MMM-yyyy HH:mm' }} minWidth={50} width={170} headerText={t('icpl.columnUnloadStart')} />
                    <ColumnDirective field='UnloadEndDate' format={{ type: 'dateTime', format: 'dd-MMM-yyyy HH:mm' }} minWidth={50} width={150} headerText={t('icpl.columnUnloadEnd')} />
                    <ColumnDirective field='LeftSiteDate' format={{ type: 'dateTime', format: 'dd-MMM-yyyy HH:mm' }} minWidth={50} width={150} headerText={t('icpl.columnLeftSite')} />
                    <ColumnDirective field='ProductName' minWidth={50} width={140} headerText={t('icpl.columnProduct')} />
                    <ColumnDirective field='ProductQuantity' minWidth={50} width={170} headerText={t('icpl.columnQuantity')} />
                    <ColumnDirective field='ExtendedCumulativeQty' minWidth={50} width={230} headerText={t('icpl.columnProgressive')} />
                    <ColumnDirective field='TotalOrder' minWidth={50} width={190} headerText={t('icpl.columnTotalOrder')} />
                    <ColumnDirective field='ExtendedSTRClass' minWidth={50} width={190} headerText={t('icpl.columnStrengthClass')} />
                    <ColumnDirective field='ExtendedDCClass' minWidth={50} width={170} headerText={t('icpl.columnExposureClass')} />
                    <ColumnDirective field='ExtendedChlorideClass' minWidth={50} width={170} headerText={t('icpl.columnChlorideClass')} />
                    <ColumnDirective field='ExtendedConsisClass' minWidth={50} width={190} headerText={t('icpl.columnConsistenceClass')} />
                    <ColumnDirective field='ExtendedLimitValue' minWidth={50} width={170} headerText={t('icpl.columnLimitValue')} />
                    <ColumnDirective field='LimitingValue2' minWidth={50} width={170} headerText={t('icpl.columnLimitValue2')} />
                    <ColumnDirective field='ExtendedTypeStrength' minWidth={50} width={230} headerText={t('icpl.columnTypeStrength')} />
                    <ColumnDirective field='TypeStrengthClass2' minWidth={50} width={230} headerText={t('icpl.columnTypeStrength2')} />
                    <ColumnDirective field='ExtendedAdmixType' minWidth={50} width={140} headerText={t('icpl.columnAdmixture')} />
                    <ColumnDirective field='ExtendedAdditionType' minWidth={50} width={170} headerText={t('icpl.columnAdditionType')} />
                    <ColumnDirective field='AggregateSize1' minWidth={50} width={210} headerText={t('icpl.columnAggregateSize')} />
                    <ColumnDirective field='AggregateSize2' minWidth={50} width={210} headerText={t('icpl.columnAggregateSize2')} />
                    <ColumnDirective field='StructuralElement' minWidth={50} width={210} headerText={t('icpl.columnStructuralElem')} />
                    <ColumnDirective field='CubesMade' minWidth={50} width={170} headerText={t('icpl.columnCubesMade')} />
                    <ColumnDirective field='Slump' minWidth={50} width={170} headerText={t('icpl.columnSlump')} />
                    <ColumnDirective field='Temperature' minWidth={50} width={190} headerText={t('icpl.columnTemperature')} />
                    <ColumnDirective field='FieldAssistant' minWidth={50} width={170} headerText={t('icpl.columnFieldAssitant')} />
                    <ColumnDirective field='Remarks' minWidth={50} width={140} headerText={t('icpl.columnRemarks')} />
                    <ColumnDirective field='DeliveryRemarks' minWidth={50} width={210} headerText={t('icpl.columnPurchaserRemarks')} />
                    <ColumnDirective field='ChemicalAdmixApplicator1' minWidth={50} width={220} headerText={t('icpl.columnChemApplicator')} />
                    <ColumnDirective field='Recipient' minWidth={50} width={245} headerText={t('icpl.columnPurchaserRepresentative')} />
                </ColumnsDirective>
                <Inject services={[Toolbar, ExcelExport, Page, Resize, Sort]} />
            </GridComponent>
            <PDFPreview ref={pdfPreviewRef} />
            <OngoingJobDialog ref={ongoingJobDialogRef} />
        </>
    );
}

export default memo(ICPLTable);

// const CRPSampleData = [
//     {
//         LoadDate: '05-Sep-2023',
//         LoadTime: '18:27',
//         TicketNum: '11312601',
//         Vehicle: '9030',
//         PlantName: 'SUNGEI KADUT',
//         ProofOfDelivery: 'SIGN ON GLASS',
//         PODurl: '11312601.pdf',
//         CustomerName: 'EXPAND CONSTRUCTION PTE LTD',
//         ProjectName: 'ZOOLOGICAL GARDENS',
//         ProjectLocation: 'MANDAI LAKE RD (INSIDE MANDAI ZOO) EPS',
//         SiteDetails: '',
//         SitePersonnel: 'MR MARCUS 93393161',
//         SiteContactNo: '',
//         Distance: '9.00',
//         ArrivedSite: '--',
//         UnloadSite: '--',
//         UnloadEnd: '--',
//         LeftSite: '--',
//         Product: 'G40 100-150 .',
//         Quantity: '3.00',
//         ProgressiveTotal: '3.00',
//         TotalOrder: '3.00',
//         CubesMade: '0',
//         Slump: '--',
//         Temperature: '--',
//         FieldAssistant: '--',
//         PurchaserRemarks: 'shaft 44',
//         ChemicalApplicator: '--',
//         PurchaseRepresentative: 'chandru'
//     },
//     {
//         LoadDate: '05-Sep-2023',
//         LoadTime: '15:15',
//         TicketNum: '11312527',
//         Vehicle: '9051',
//         PlantName: 'SUNGEI KADUT',
//         ProofOfDelivery: 'SIGN ON GLASS',
//         PODurl: '11312527.pdf',
//         CustomerName: 'EXPAND CONSTRUCTION PTE LTD',
//         ProjectName: 'ZOOLOGICAL GARDENS',
//         ProjectLocation: 'MANDAI LAKE RD (INSIDE MANDAI ZOO) SHAFT 44',
//         SiteDetails: '',
//         SitePersonnel: 'MR RYAN 83627918',
//         SiteContactNo: '',
//         Distance: '9.00',
//         ArrivedSite: '--',
//         UnloadSite: '--',
//         UnloadEnd: '--',
//         LeftSite: '--',
//         Product: 'G40 100-150 .',
//         Quantity: '9.00',
//         ProgressiveTotal: '9.00',
//         TotalOrder: '9.00',
//         CubesMade: '0',
//         Slump: '--',
//         Temperature: '--',
//         FieldAssistant: '--',
//         PurchaserRemarks: '',
//         ChemicalApplicator: '--',
//         PurchaseRepresentative: 'shaft44 praba'
//     },
//     {
//         LoadDate: '05-Sep-2023',
//         LoadTime: '13:09',
//         TicketNum: '11312475',
//         Vehicle: '9192',
//         PlantName: 'SUNGEI KADUT',
//         ProofOfDelivery: 'SIGN ON GLASS',
//         PODurl: '11312475.pdf',
//         CustomerName: 'EXPAND CONSTRUCTION PTE LTD',
//         ProjectName: 'ZOOLOGICAL GARDENS',
//         ProjectLocation: 'MANDAI LAKE RD (INSIDE MANDAI ZOO)',
//         SiteDetails: '',
//         SitePersonnel: 'MR JAMIE 90889590',
//         SiteContactNo: '',
//         Distance: '9.00',
//         ArrivedSite: '--',
//         UnloadStart: '05-Sep-2023 13:59',
//         UnloadEnd: '05-Sep-2023 13:59',
//         LeftSite: '--',
//         Product: 'G40 100-150 .',
//         Quantity: '2.00',
//         ProgressiveTotal: '2.00',
//         TotalOrder: '2.00',
//         CubesMade: '0',
//         Slump: '--',
//         Temperature: '--',
//         FieldAssistant: '--',
//         PurchaserRemarks: '',
//         ChemicalApplicator: '--',
//         PurchaseRepresentative: 'hossain md sabbir'
//     },
//     {
//         LoadDate: '05-Sep-2023',
//         LoadTime: '10:12',
//         TicketNum: '14380454',
//         Vehicle: '9210',
//         PlantName: 'PUNGGOL TIMOR',
//         ProofOfDelivery: 'SIGN ON GLASS',
//         PODurl: '14380454.pdf',
//         CustomerName: 'EXPAND CONSTRUCTION PTE LTD',
//         ProjectName: 'BIDADARI ESTATE',
//         ProjectLocation: 'MOUNT VERNON RD LP 4A (HDB PUMPING STATION)',
//         SiteDetails: '',
//         SitePersonnel: 'MS SIM 98582857',
//         SiteContactNo: '',
//         Distance: '13.00',
//         ArrivedSite: '--',
//         UnloadStart: '05-Sep-2023 11:17',
//         UnloadEnd: '05-Sep-2023 12:10',
//         LeftSite: '--',
//         Product: 'G40 100-150 OPC .',
//         Quantity: '4.50',
//         ProgressiveTotal: '4.50',
//         TotalOrder: '4.50',
//         CubesMade: '0',
//         Slump: '--',
//         Temperature: '--',
//         FieldAssistant: '--',
//         PurchaserRemarks: '',
//         ChemicalApplicator: '--',
//         PurchaseRepresentative: 'manickam'
//     },
// ];