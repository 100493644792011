import FindPlusIntegration from "../../../services/findplus_integration";

export default function SignatureLoader(signatureCallback) {
    const signatures = [];
    let queue = [];
    let isInit = false;
    let timeout = null;

    this.cancelQueue = () => {
        queue = [];
    }

    this.addQueue = (deliveryKey, callback) => {
        if (deliveryKey) {
            queue.push({
                deliveryKey,
                callback
            });
    
            if (!timeout) {
                timeout = setTimeout(() => {
                    if (queue.length > 0 && !isInit) this.init();
                    timeout = null;
                }, 200);
            }
        }
    }

    this.init = async () => {
        const proofService = FindPlusIntegration.getProofService();

        isInit = true;
        do {
            const item = queue.shift();
            if (item) {
                //Check if signatures is already fetched
                const signature = signatures.filter(f => f.deliveryKey === item.deliveryKey);
                if (signature.length === 0) {
                    try {
                        const proofReq = await proofService.getProof(item.deliveryKey);
                        const proof = proofReq.data;
                        
                        signatures.push({
                            deliveryKey: item.deliveryKey,
                            data: proof
                        });

                        if (item.callback) {
                            item.callback({
                                isSuccess: true,
                                data: proof
                            });
                        }
                    } catch (_) {
                        item.callback({
                            isSuccess: false
                        });
                    }

                    //request delay
                    await new Promise((resolve) => {
                        setTimeout(() => {
                            resolve();
                        }, 1000);
                    });
                }
                else {
                    item.callback({
                        isSuccess: true,
                        data: signature[0].data
                    });
                }
            }
            signatureCallback(signatures);
        } while (queue.length > 0);
        isInit = false;
    }
}