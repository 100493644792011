import { axiosICPLBEInstance } from "../../../utils/axios_config";

export default function EPDFService() {

    this.getStreamedPDF = ticketNum => {
        return `${axiosICPLBEInstance.defaults.baseURL}getPdf/${ticketNum}?stream=true`;
    }

    this.downloadPDF = ticketNum => {
        return axiosICPLBEInstance.get(`download/${ticketNum}`, {
            responseType: 'blob'
        });
    }

    this.downloadMergedPDF = ticketNums => {
        return axiosICPLBEInstance.post('merge', {
            eDO: ticketNums
        }, {
            responseType: 'blob'
        });
    }
}