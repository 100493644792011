import moment from "moment";

function ICPLFilterUtils(data) {
    this.filter = data;

    this.getFilterHeader = function () {
        let headerTitle = "Filter";
        const keys = Object.keys(this.filter);
        if (keys.length) {
            headerTitle += ' - ';
            keys.forEach((key) => {
                if (this.filtersLabels[key] && this.filter[key]) {
                    if (key === 'StartDateTime' || key === 'EndDateTime') headerTitle += `${this.filtersLabels[key]}: ${moment(this.filter[key]).format('yyyy-MMM-DD HH:mm')}, `
                    else headerTitle += `${this.filtersLabels[key]}: ${this.filter[key]}, `
                };
            });

            return headerTitle.substring(0, (headerTitle.length - 2));
        }

        return headerTitle;
    }
    this.mapToExcel = function () {
        let mapped = [];
        const keys = Object.keys(this.filter);
        if (keys.length) {
            keys.forEach((key) => {
                if (this.filtersLabels[key] && this.filter[key]) {
                    if (key === 'StartDateTime' || key === 'EndDateTime') {
                        mapped.push({
                            title: this.filtersLabels[key],
                            value: moment(this.filter[key]).toISOString(),
                            isDateTime: true
                        });
                    }
                    else {
                        mapped.push({
                            title: this.filtersLabels[key],
                            value: this.filter[key],
                            isDateTime: false
                        });
                    }
                };
            });
        }

        return mapped;
    }
}

ICPLFilterUtils.prototype.filtersLabels = {
    StartDateTime: 'Start',
    EndDateTime: 'End',
    DeliveryStatus: 'Status',
    Vehicle: 'Vehicle',
    CustomerName: 'Customer Name',
    CustomerCode: 'Customer Code',
    Origin: 'Batching Plant',
    Destination: 'Project Site'
}

export default ICPLFilterUtils;