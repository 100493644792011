import { forwardRef, useImperativeHandle, useState } from "react";
import { DialogComponent } from "@syncfusion/ej2-react-popups";
import QRCode from "react-qr-code";

const OngoingJobDialog = forwardRef((_, ref) => {
    const [dialogConfig, setDialogConfig] = useState({
        visible: false,
        header: '',
        data: {}
    })

    useImperativeHandle(ref, () => {
        return {
            show(data) {
                setDialogConfig({
                    visible: true,
                    header: `[${data.Vehicle}] DO ${data.TicketNum}`,
                    data: data
                });
            }
        }
    });

    const handleDialogClose = () => {
        setDialogConfig({
            visible: false,
            header: '',
            data: {}
        });
    }

    return (
        <DialogComponent
            id="icpl_qr_dialog"
            close={handleDialogClose}
            closeOnEscape={false}
            width="300px"
            isModal={true}
            showCloseIcon={true}
            header={dialogConfig.header}
            height={360}
            visible={dialogConfig.visible}
        >
            {dialogConfig.visible && <div style={{ background: 'white', padding: '16px', borderTop: '1px solid rgba(0, 0, 0, .2)' }}>
                <QRCode
                    value={dialogConfig.data?.TicketNum}
                />
            </div>}
        </DialogComponent>
    )
});

export default OngoingJobDialog;