import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import { shallowEqual, useSelector } from "react-redux";
import { icplSelector } from "../redux/icpl_slice";
import { useEffect, useRef } from "react";
import { createSpinner, showSpinner } from "@syncfusion/ej2-react-popups";

export default function PaginationCtrl({ gridRef, pagerData }) {
    const { loading } = useSelector(icplSelector(['loading']), shallowEqual);
    const isSpinnerCreated = useRef(false);

    useEffect(() => {
        if (!isSpinnerCreated.current) {
            isSpinnerCreated.current = true;
            createSpinner({
                // Specify the target for the spinner to show
                target: document.getElementById('pagination-loader'),
            });
            showSpinner(document.getElementById('pagination-loader'));
        }
    }, []);

    if (gridRef) {
        let currentPage = gridRef.pageSettings.currentPage;
        const dataCount = gridRef.dataSource.length;
        const pageSize = gridRef.pageSettings.pageSize;
        const maxPage = Math.ceil(dataCount / pageSize);

        // console.log('-------------------');
        // console.log(pageSize);
        // console.log(dataCount);

        const showLoader = currentPage > 1 && loading;
        console.log(showLoader);
        return (
            <>
                <div className="e-pagercontainer" role="navigation" style={{position: 'relative'}}>
                    {/* <div className="e-first e-icons e-icon-first e-firstpagedisabled e-disable" title="Go to first page" aria-label="Go to first page" tabIndex="-1" index="1" aria-owns="grid_452549681_0_content_table"></div> */}
                    <div className={`e-prev e-icons e-icon-prev ${currentPage > 1 ? 'e-prevpage' : 'e-firstpagedisabled e-disable'}`} title="Go to previous page" aria-label="Go to previous page" tabIndex="-1" index="0" onClick={() => {
                        if ((currentPage - 1) !== 0) gridRef.pageSettings.currentPage = --currentPage;
                    }}></div>
                    {/* e-nextpagedisabled e-disable */}
                    <div className={`e-next e-icons e-icon-next ${maxPage > currentPage ? 'e-nextpage' : 'e-nextpagedisabled e-disable'}`} title="Go to next page" aria-label="Go to next page" tabIndex="-1" index="2" onClick={() => {
                        if (currentPage <= maxPage) gridRef.pageSettings.currentPage = ++currentPage;
                    }}  style={{color: showLoader ? 'transparent' : undefined}}></div>
                    <div id="pagination-loader" style={{display: showLoader ? 'inline-block' : 'none'}}></div>
                    {/* e-lastpagedisabled e-disable */}
                    {/* <div className="e-last e-icons e-icon-last e-lastpage" title="Go to last page" aria-label="Go to last page" tabIndex="-1" index="0" aria-owns="grid_452549681_0_content_table"></div> */}
                </div>
                <div className="e-pagesizes">
                    <div className="e-pagerdropdown">
                        <DropDownListComponent dataSource={[10, 20, 50, 100, 200]} value={pagerData.pageSize} onChange={(e) => {
                            if (gridRef) {
                                gridRef.pageSettings.pageSize = e.target.value;
                                setTimeout(() => {
                                    gridRef.hideSpinner();
                                }, 100);
                            }
                        }} />
                    </div>
                    <div className="e-pagerconstant">
                        <span className="e-constant">Items per page</span>
                    </div>
                </div>
            </>
        );
    }

    return null;
}