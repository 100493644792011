const StateSelector = (reducerKey) => items => state => {
    if (items && items.length > 0) {
        let selector = {};
        items.forEach(val => {
            selector = {
                ...selector,
                [val]: state[reducerKey][val]
            }
        });

        return selector;
    }

    return state[reducerKey];
}

export default StateSelector;