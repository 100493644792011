import { axiosInstance } from "../../../utils/axios_config"

export default function IntegrationZoneService() {

    this.getProjectSites = async () => {
        return axiosInstance.post('Zone');
    }

    this.getOrigins = async () => {
        return axiosInstance.post('Zone/origin');
    }
}