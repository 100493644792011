export default function DeliveryOrders() {}

DeliveryOrders.mapOrders = orders => {
    orders = [].concat(orders)
    if (orders && orders.length > 0) {
        return orders.map(order => {
            //Map Chemical Mixed Array 
            let chemicalAdmix = {};
            for (let i = 0; i < (order.ChemicalAdmix ?? []).length; i++) {
                const value = order.ChemicalAdmix[i];
                chemicalAdmix = {
                    ...chemicalAdmix,
                    [`ChemicalAdmixApplicator${i + 1}`]: value.Applicator,
                    [`ChemicalAdmixRemarks${i + 1}`]: value.Remarks,
                    [`ChemicalAdmixStamp${i + 1}`]: value.Stamp,
                    [`ChemicalAdmixTimestamp${i + 1}`]: value.Timestamp,
                }
            }

            //Map Extended Object
            let extended = {};
            Object.keys(order.Extended ?? {}).forEach(key => {
                extended = {
                    ...extended,
                    [`Extended${key}`]: order.Extended[key]
                }
            });

            //Map Project Object
            let product = {};
            Object.keys(order.Product ?? {}).forEach(key => {
                product = {
                    ...product,
                    [`Product${key}`]: order.Product[key]
                }
            });

            if (order.ChemicalAdmix) delete order.ChemicalAdmix;
            if (order.Extended) delete order.Extended;
            if (order.Product) delete order.Product;

            return {
                ...order,
                ...chemicalAdmix,
                ...extended,
                ...product
            }
        });
    }

    return [];
}