import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { icplSelector, setTextSearch, setTriggerSearch } from "../redux/icpl_slice";

export default function TableToolbar() {
    const dispatch = useDispatch();
    const { textSearch } = useSelector(icplSelector(['textSearch']), shallowEqual);

    return (
        <div className="table-toolbar">
            <TextBoxComponent
                id="txtbox_table_search"
                cssClass="e-outline"
                value={textSearch}
                placeholder="Search"
                floatLabelType='Auto'
                onChange={(e) => dispatch(setTextSearch(e.value))}
                created={() => {
                    document.getElementById('txtbox_table_search').addEventListener('keypress', e => {
                        if (e.code === 'Enter') {
                            dispatch(setTriggerSearch());
                            //onChange(e.target.value);
                        }
                    });
                }}
                width={'200px'} />
        </div>
    )
}